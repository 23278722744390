@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TTHoves";
  font-weight: 400;
  src: local("TTHoves"),
    url(./fonts/TTHoves/TTHoves-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "TTHoves";
  font-weight: 500;
  src: local("TTHoves"),
    url(./fonts/TTHoves/TTHoves-Medium.ttf) format("opentype");
}

@font-face {
  font-family: "TTHoves";
  font-weight: 600;
  src: local("TTHoves"),
    url(./fonts/TTHoves/TTHoves-DemiBold.ttf) format("opentype");
}

@font-face {
  font-family: "TTHoves";
  font-weight: 700;
  src: local("TTHoves"),
    url(./fonts/TTHoves/TTHoves-Bold.ttf) format("opentype");
}

* {
  font-family: "TTHoves", sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}

:root {
  /* Transparent */
  --transparent: transparent;

  /* Dark Background */
  --dark-background-primary: #101012;
  --dark-background-secondary: #1c1c1f;
  --dark-background-alpha: rgba(34, 35, 38, 0.64);

  /* Dark Brand */
  --dark-brand-primary: #2bcf69;
  --dark-brand-secondary: #20aa54;
  --dark-brand-alpha: rgba(2, 192, 74, 0.16);

  /* Dark Source */
  --dark-source-black-50: rgba(0, 0, 0, 0.5);
  --dark-source-black-64: rgba(0, 0, 0, 0.64);
  --dark-source-black: #000000;

  /* Dark Source Container */
  --dark-source-container-black: rgba(0, 0, 0, 0.12);
  --dark-source-container-error: rgba(243, 40, 40, 0.12);
  --dark-source-container-info: rgba(32, 88, 187, 0.12);
  --dark-source-container-success: rgba(0, 164, 0, 0.12);
  --dark-source-container-warning: rgba(255, 165, 0, 0.12);
  --dark-source-container-white: rgba(255, 255, 255, 0.12);

  /* Dark Source Error, Info, Scrim, Success, Warning, White */
  --dark-error: #f32828;
  --dark-info: #2058bb;
  --dark-scrim-black: rgba(0, 0, 0, 0.72);
  --dark-scrim-white: rgba(255, 255, 255, 0.72);
  --dark-success: #00a400;
  --dark-warning: #ffa500;
  --dark-white: #ffffff;

  /* Dark Surface */
  --dark-surface-high: #1c1c1f;
  --dark-surface-low: #292b2e;
  --dark-surface-medium: #222326;

  /* Dark Utility */
  --dark-utility-disabled: #21272c;
  --dark-utility-high: #f5f7fa;
  --dark-utility-low: #46525c;
  --dark-utility-medium: #646c78;

  /* Light Background */
  --light-background-primary: #f4f6fa;
  --light-background-secondary: #ffffff;
  --light-background-alpha: rgba(244, 246, 250, 0.64);
  --light-background-gradient: linear-gradient(
    90deg,
    #fff 32.05%,
    rgba(255, 255, 255, 0) 100%
  );

  /* Light Brand */
  --light-brand-primary: #02c04a;
  --light-brand-secondary: #049b3d;
  --light-brand-alpha: rgba(2, 192, 74, 0.16);

  /* Light Source, Surface, and Utility */
  --light-source-black-50: rgba(0, 0, 0, 0.5);
  --light-source-black-64: rgba(0, 0, 0, 0.64);
  --light-source-black: #000000;

  --light-source-container-black: rgba(0, 0, 0, 0.12);
  --light-source-container-error: rgba(243, 40, 40, 0.12);
  --light-source-container-info: rgba(32, 88, 187, 0.12);
  --light-source-container-success: rgba(0, 164, 0, 0.12);
  --light-source-container-warning: rgba(255, 165, 0, 0.12);
  --light-source-container-white: rgba(255, 255, 255, 0.12);

  --light-error: #f32828;
  --light-info: #2058bb;
  --light-scrim-black: rgba(0, 0, 0, 0.72);
  --light-scrim-white: rgba(255, 255, 255, 0.72);
  --light-success: #00a400;
  --light-warning: #ffa500;
  --light-white: #ffffff;

  --light-surface-high: #ffffff;
  --light-surface-low: #dfe4eb;
  --light-surface-medium: #ebeef2;

  --light-utility-disabled: #cdd4e0;
  --light-utility-high: #13171a;
  --light-utility-low: #9ba5b5;
  --light-utility-medium: #62717c;
}

/* Change the white to any color */
/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
} */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
